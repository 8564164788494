$primary:#366DB0;
$secondary:#366DAF1A;
$textColor:#0C2544;
$textSecondaryColor:#708595;

$warning: #DD841C;
$info: #ffd900;
$success: #42D18C;
$danger: #EF434D;
$dangerBg: #EF434D1A;
$transparent: #00000000;

$white-100: #FFFEFC;
$white-200: #FCFCFD;
$white-300: #FFFFFF0A;

$grey-100: #F4F4F5;
$grey-200: #F4F4F4;
$grey-250: #CACEDB;
$grey-300: #45455D0F;
$grey-400: #01288C0A;
$grey-500: #F0F4FF;
$grey-600: #F2F3F5;
$grey-700: #E2E4E8;

$blue-100: #01288C0A;
$blue-200: #F0F4FF;
$blue-300: #CFD7E6;
$blue-400: #1B357980;
$blue-500: #002FA6;
$blue-600: #93A0BA;

$white-70: #ffffffb0;
$white-light: #F2F4F824;
$primary-light: #f392001a;
$primary-50: #366DAF1A;
$secondary-light: #fcf0eb;
$warning-light: #FFDEDB;
$success-light: #42D18C1A;
$danger-light: #FFDEDB;
$info-light: #fff8e6;

