/* Reset */
body{
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size:1rem;
  color: $primary;
}

*:focus {
  outline: none !important;
}

/* Reset elements*/
button{
  background: none;
  padding: 0;
  font-weight: 700;
}

ul{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}

hr{
  border-color: $grey-700;
  margin: 0.5em 0;
}

/* Reset font */
h1, h2, h3, h4, b {
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: $textColor;
}

p{
  margin: 0;
}

h1{font-size: toRem(28)}
h2{font-size: toRem(20)} // 20px
h3,ion-input,input{font-size: toRem(18);} // 18px
h4,ion-label{font-size: toRem(16)} // 16px
h5{font-size: toRem(14)}//14px
small{font-size: toRem(12)}//12px

/* Fonts size */
.fs-11{
  font-size: toRem(11)!important;
}
.fs-12{
  font-size: toRem(12)!important;
}
.fs-13{
  font-size: toRem(13)!important;
}
.fs-14{
  font-size: toRem(14)!important;
}
.fs-15{
  font-size: toRem(15)!important;
}
.fs-16{
  font-size: toRem(16)!important;
}
.fs-18{
  font-size: toRem(18)!important;
}
.fs-20{
  font-size: toRem(20)!important;
}
.fs-24{
  font-size: toRem(24)!important;
}
.fs-28{
  font-size: toRem(28)!important;
}
.fs-30{
  font-size: toRem(30)!important;
}
.fs-35{
  font-size: toRem(35)!important;
}
.fs-45{
  font-size: toRem(45)!important;
}

/* Fonts weight */
.fw-medium{
  font-weight: 500;
}
.fw-bold{
  font-weight: 700;
}

/* Backgrounds */
.bg-none{
  background: none!important;
}
.bg-transparent{
  background: $transparent;
  --background: $transparent;
  background-color:rgba(0,0,0,0);
}
.bg-white-light{
  background: $white-light;
  --background: $white-light;
}
.bg-primary-light{
  background: $primary-light;
  --background: $primary-light;
}
.bg-secondary-light{
  background: $secondary-light;
  --background: $secondary-light;
}
.bg-success-light{
  background: $success-light;
  --background: $success-light;
}
.bg-danger-light{
  background: $danger-light;
  --background: $danger-light;
}
.bg-warning-light{
  background: $warning-light;
  --background: $warning-light;
}
.bg-info-light{
  background: $info-light;
  --background: $info-light;
}
.bg-grey-400{
  background: $grey-400;
  --background: $grey-400;
}
.bg-grey-250{
  background: $grey-250;
  --background: $grey-250;
}

/* Colors */
.text-white{
  color: #FFFF;
}
.text-primary-50{
  color: $primary-50;
}

.text-secondary{
  color: $textSecondaryColor !important;
}

.text-primary{
  color: $primary;
}


/* border colors */
.border-grey-200{
  border-color: $grey-200!important;
}
.border-danger{
  border-color: $danger!important;
}
.border-success{
  border-color: $success!important;
}
.border-warning{
  border-color: $warning!important;
}
.border-none{
  border: none!important;
}

/* rounded */
.rounded-4{
  border-radius: 4px!important;
}
.rounded-15{
  border-radius: 15px!important;
}
.rounded-20{
  border-radius: 20px!important;
}

/* Helpers */
.no-break-word{
  white-space: nowrap;
}
.break-word{
  white-space: normal!important;
}
.bg-none{
  --background: $transparent!important;
  background: $transparent!important;
}
.shadow-20{
  box-shadow: 0px 10px 20px #45455D0F;
}
.shadow-none{
  box-shadow: none!important;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-none{
  pointer-events: none;
}
.z-index-0{
  z-index: 1000;
}
.z-index-1{
  z-index: 1001;
}

.text-underline{
  text-decoration: underline;
}

.gradient-bg-head{
  background: #002FA7;
}
.gradient-bg{
  $list: #002FA7 0%, #012274 100%;
  @include gradient(180deg, $list);

  @include not-mobile{
    --background: none;
  }
}

.full-screen {
  display: flex;
  flex-flow: column;
  height: 100%;

  .row.content {
    flex: 1 1 auto;
  }
}

.max-w-none{
  max-width: none;
}

ion-toast {
  margin-top:11px;
  --border-width: 0 0 0 15px;
  --border-style: solid;
  --max-width: 400px;

  &.success{
    --background : #FFFFFF!important;
    --color : #42D18C!important;
    --border-color: #42D18C;

    &::part(message)::before{
      font-family: 'Font Awesome 6 Pro';
      content: "";
      padding-right: 15px;
    }
  }

  &.warning{
    --background : #FFFFFF!important;
    --color : #F8DF54!important;
    --border-color: #F8DF54;

    &::part(message)::before{
      font-family: 'Font Awesome 6 Pro';
      content: "";
      padding-right: 15px;
    }
  }

  &.error{
    --background : #FFFFFF!important;
    --color : #EF434D!important;
    --border-color: #EF434D;

    &::part(message)::before{
      font-family: 'Font Awesome 6 Pro';
      content: "";
      padding-right: 15px;
    }
  }

}


ion-input,input {
  height: 56px;
  border-radius: 7px !important;
  border:solid 1px #366DAF33 !important;
  &:focus{
    box-shadow: none !important;
  }
  &.error{
    border-color: $danger !important;
  }
  &:read-only{
    color: #708595;
    cursor: auto;
    &:focus{
      color: #708595;
    }
  }
}

label{
  color: $textSecondaryColor;
  font-size: 1rem;
  &.required::after{
    content: '*';
    color: $primary;
  }
}

.loading-wrapper{
  --background: white;
  background-color:white;
  height: 70px;
  width: 70px;
  border-radius: 6px;

  ion-spinner{
    --color: $primary;
    color:$primary;
  }
}

ion-select{
  border: solid 1px #366DAF33;
  border-radius: 7px;
  --highlight-color-focused: none;
  --padding-start: 15px;
  --padding-end: 15px;
  --highlight-color-valid: none;
  --highlight-color-invalid: none;
  height: 56px;
  &::part(placeholder) {
    color: $primary;
    opacity: 1;
  }
  &::part(icon) {
    color: $primary;
  }
  &::part(icon):host(.select-expanded) {
    color: $primary;
  }
  &.error{
    border-color: $danger !important;
  }
}

ion-content{
  ion-header{
    margin-top: 30px;
    box-shadow: none !important;
    ion-toolbar{
      --background: #EEF2F4 !important;
      background: #EEF2F4 !important;
    }
    ion-title{
      padding: 0;
      color: $textColor;
      font-size: 1.3em;
      font-weight: bold;
      i{
        margin-right: 15px;
      }
      i:nth-child(2){
        font-size: 0.8em;
        margin-left: 15px;
      }
      span.text-primary{
        cursor: pointer;
      }
    }
  }
}

.admin-content{
  ion-header{
    max-width: 1320px;
  }
  .content{
    max-width: 1320px;
  }
}

.main-content {
  padding: 40px;
  min-height: 100px;
  background-color: white;
  border-radius: 10px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 151px;
}

.doctor-view {
  --padding-start: 50px;
  --padding-end: 50px;
  .container {
    max-width: 100%;
  }
}

ion-badge{
  position: relative !important;
  bottom: -7px !important;

  font-size: 1rem;
  font-weight: normal;

  border-radius: 17px;
  padding: 6px 24px;
  margin-left: 15px;
  &.warning{
    background: #F79F2821;
    color: #F79F28;
    border: solid 1px #F79F28;
  }

  &.success{
    background: #36AF7221;
    color: #36AF72;
    border: solid 1px #36AF72;
  }
}

.table {

  th{
    border: 0;
    font-size: 1em;
    font-weight: normal;
    color: #708595;
    border-bottom: 0px solid #dee2e6!important;
    padding-left: 15px;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: #EEF2F4;
    td{
      background-color: #EEF2F4;
      --bs-table-color-type: none;
      --bs-table-bg-type: none;
    }
  }

  tbody{
    border:0;
  }

  td{
    border: 0;
    padding: 22px 15px;
    ion-badge{
      bottom: -4px !important;
      margin: 0;
    }
  }

  .actions{
    min-width: 100px!important;
  }

  .verticalalign-middle{
    vertical-align: middle;
  }

  .table-link{
    display: inline-block;
    background-color: transparent!important;
    color: #366DAF!important;
    font-size: 1em!important;
    &.red{
      color: #EF434D !important;
    }
  }
}

.text-right {
  text-align: right;
}

textarea:focus{
  box-shadow: none !important;
}

textarea{
  &.error{
    border-color: #EF434D !important;
  }
  max-height: 360px;
  @media screen and (max-height: 800px) {
    max-height: 250px;
  }
}

ion-modal.detailsModal {
  --height: 465px;
  --width: 465px;
  --border-radius: 12px;
}

ion-modal.detailImageModal {
  --height: 670px;
  --width: 700px;
  --border-radius: 12px;
}

ion-modal.addImageModal {
  --height: 670px;
  --width: 750px;
  --border-radius: 12px;
}
