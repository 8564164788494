// TO use it :
//
// @include tablet {
//    .myClass {}
// }

// Breakpoints based on bootstrap xs md lg
@mixin not-mobile-small {
  @media screen and (min-width: 576px) { @content; }
}

@mixin mobile-small {
  @media screen and (max-width: 575px) { @content; }
}

@mixin mobile {
  @media screen and (max-width: 767px) { @content; }
}

@mixin not-mobile {
  @media screen and (min-width: 768px) { @content; }
}

@mixin tablet {
  @media screen and (max-width: 991px) { @content; }
}

@mixin desktop {
  @media screen and (min-width: 992px) { @content; }
}

@mixin desktop-13 {
  @media screen and (min-device-width: 992px) and (max-device-width: 1400px) { @content; }
}

@mixin desktop-wide {
  @media screen and (min-width:1400px) { @content; }
}


// Transition
@mixin transition($property,$duration:1s){
  -webkit-transition: $property $duration;
  -moz-transition: $property $duration;
  -ms-transition: $property $duration;
  -o-transition: $property $duration;
  transition: $property $duration;
}

// Gradients
@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);

  --background: -webkit-linear-gradient($direction, $list);
  --background: -moz-linear-gradient($direction, $list);
  --background: -o-linear-gradient($direction, $list);
  --background: linear-gradient($direction, $list);
}

// Converter
@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}
