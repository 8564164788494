// Set primary bootstrap color
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warning": $warning,
  "info": $info,
  "success": $success,
  "danger": $danger,
);

@import "./node_modules/bootstrap/scss/bootstrap";

/*
  button-variant(
  $background:
  $border:
  $color:
  $hover-background:
  $hover-border:
  $hover-color:
  $active-background:
  $active-border:
  $active-color:
  $disabled-background:
  $disabled-border:
  $disabled-color:
  )
  */
.btn-primary {
  @include button-variant(
      $primary,
      $transparent,
      $white,
      //hover
      darken($primary, 10%),
      $transparent,
      $white,
      //active
      $primary,
      $transparent,
      lighten($primary, 10%),
      //disabled
      lighten($primary, 30%),
      $transparent,
      $white
  );

  border-radius: 10px;
  max-width: 250px;
  padding: 10px 25px;
  font-weight: 700;
  font-size: 1.125rem; // 18px
  @include mobile-small{
    max-width: 400px;
  }
}

  /*
    $color:
    $color-hover:
    $active-background:
    $active-border:
    $active-color:
  */
.btn-outline-primary {
  @include button-outline-variant(
      $primary,
      darken($primary, 10%),
      $transparent,
      darken($primary, 10%),
      $white
  );
  border-radius: 10px;
  max-width: 250px;
  padding: 10px 25px;
  font-weight: 700;
  font-size: 1.125rem; // 18px
  @include mobile-small{
    max-width: 400px;
  }
}

.btn-secondary {
  @include button-variant(
      $secondary,
      $transparent,
      $primary,
      //hover
      darken($secondary, 10%),
      $transparent,
      $primary,
      //active
      $secondary,
      $transparent,
      lighten($secondary, 10%),
      //disabled
      darken($secondary, 10%),
      $transparent,
      $primary
  );

  border-radius: 10px;
  max-width: 250px;
  padding: 10px 25px;
  font-weight: 700;
  font-size: 1.115rem; // 18px
  @include mobile-small{
    max-width: 400px;
  }
}

/*
  $color:
  $color-hover:
  $active-background:
  $active-border:
  $active-color:
*/
.btn-outline-secondary {
  @include button-outline-variant(
      $secondary,
      darken($secondary, 10%),
      $transparent,
      darken($secondary, 10%),
      $white
  );
  border-radius: 50px;
  max-width: 250px;
  padding: 10px 25px;
  font-weight: 700;
  font-size: 1.125rem; // 18px
  @include mobile-small{
    max-width: 400px;
  }
}

.btn-danger{
  border-radius: 50px;
  @include button-variant(
      $dangerBg,
      $transparent,
      $danger,
      //hover
      darken($dangerBg, 10%),
      $transparent,
      darken($danger, 10%),
      //active
      $dangerBg,
      $transparent,
      lighten($dangerBg, 10%),
      //disabled
      lighten($dangerBg, 30%),
      $transparent,
      $danger
  );

  border-radius: 10px;
  width: 100%;
  max-width: 250px;
  padding: 10px 25px;
  font-weight: 700;
  font-size: 1.125rem; // 18px
  @include mobile-small{
    max-width: 400px;
  }
}


