.header-md{
  background-image: none;
  box-shadow: 0px 5px 20px 0px #6A6D6F0D;
  border-bottom: 1px solid #F2F2EF;
  --background: white;
  background: white;
  &::after {
    background-image: none;
  }
}

.footer-md {
  background: white;
  --background: white;
  color: white;
  border: 0;
  box-shadow: 0px -5px 15px 0px #11153E14;
  &::before {
    background-image: none;
  }
}

.ion-no-border{
  box-shadow: none!important;
  border: 0!important;
}

ion-content{
  --background: #EEF2F4;
  background: #EEF2F4;
}

